<template>
	<autocomplete-field label="User" @input="userSearch" v-model="keyword" :options="userOptions" @select="userSelect" autocomplete="nope" :loading="userLoading" />
</template>

<script>
import AutocompleteField from '@/components/AutocompleteField'

export default {
	name: 'AutocompleteUser',
	components: {
		AutocompleteField,
	},
	props: {
		orgId: null,
	},
	data: () => ({
		keyword: null,
		userOptions: [],
		userLoading: false,
	}),
	computed: {
		users() {
			const users = this.$store.getters['user/users']
			return users.map(u => { return { value: u.uid, label: this.$options.filters.user(u, ' ', ' - ') }} )
		},
	},
	async mounted() {
		// await this.$store.dispatch('user/list')
	},
	methods: {
		async userSearch(value) {
			if (!value || value.length < 4) return ''
			this.userLoading = true
			this.$store.dispatch('user/list', {
				key: 'keywords.name,keywords.orgId',
				op: 'array-contains',
				value: [value, this.orgId].join(`,`)
			})
				.then((results) => {
					this.userOptions = results.filter(r => r.status != 'removed').map(r => { return { value: r.uid, label: this.$options.filters.user(r, ' ', ' - '), raw: r }})
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.userLoading = false
				})
			// this.userLoading = false
		},
		userSelect(selected) {
			this.keyword = selected.label
			this.$emit('selected', selected)
		},
	},
	watch: {
		$attrs: {
			immediate: true,
			handler: function (newVal, oldVal) {
				if (newVal.label ){
					this.keyword = newVal.label
				}
				// if (newVal.value) {
				// 	let user = this.users.find(u => u.value == newVal.value)
				// 	if (user) {
				// 		this.keyword = user.label
				// 	}
				// }
			},
		},
		// users(newVal) {
		// 	console.log('users.watch', this.$attrs.value, newVal)
		// 	if (this.$attrs.value) {
		// 		let user = newVal.find(u => u.value == this.$attrs.value)
		// 		if (user) {
		// 			this.keyword = user.label
		// 		}
		// 	}

		// },
	},
}
</script>

<style scoped lang="scss">

</style>
