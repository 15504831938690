<template>
	<div class="presentation-list-menu mdc-menu-surface--anchor">
		<loading-line class="presentation-list__loading" v-if="isLoading" />
		<button :id="`menu_button_${id}`" class="mdc-icon-button" @click="toggleMenu" v-if="!isLoading">
			<ion-icon name="more"></ion-icon>
		</button>
		<div class="mdc-menu mdc-menu-surface">
			<ul class="mdc-list mdc-list--dense mdc-list--icon-list" role="menu" aria-hidden="true" aria-orientation="vertical" tabindex="-1">
				<li class="mdc-list-item" role="menuitem" @click="sendPresentation(presentation)">
					<span class="mdc-list-item__ripple"></span>
					<span class="mdc-list-item__graphic">
						<ion-icon name="share-alt"></ion-icon>
					</span>
					<span class="mdc-list-item__text">Send</span>
				</li>
				<li class="mdc-list-item" role="menuitem" @click="viewPresentation(presentation)">
					<span class="mdc-list-item__ripple"></span>
					<span class="mdc-list-item__graphic">
						<ion-icon name="eye"></ion-icon>
					</span>
					<span class="mdc-list-item__text">View</span>
				</li>
				<li class="mdc-list-item" role="menuitem" @click="copyUrl(presentation)">
					<span class="mdc-list-item__ripple"></span>
					<span class="mdc-list-item__graphic">
						<ion-icon name="link"></ion-icon>
					</span>
					<span class="mdc-list-item__text">Copy URL</span>
				</li>
				<li class="mdc-list-item" role="menuitem" @click="updatePresentation(presentation)">
					<span class="mdc-list-item__ripple"></span>
					<span class="mdc-list-item__graphic">
						<ion-icon name="refresh"></ion-icon>
					</span>
					<span class="mdc-list-item__text">Update Rates</span>
				</li>
				<li class="mdc-list-item" role="menuitem" @click="deletePresentation(presentation)">
					<span class="mdc-list-item__ripple"></span>
					<span class="mdc-list-item__graphic">
						<ion-icon name="trash"></ion-icon>
					</span>
					<span class="mdc-list-item__text">Delete</span>
				</li>
			</ul>
			<input type="hidden" :id="`copy_field_${presentation.presentationId}`" />
		</div>
	</div>
</template>

<script>
import { v4 } from 'uuid'
import { MDCMenu } from '@material/menu'
import LoadingLine from '@/components/LoadingLine'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
	name: 'PresentationListMenu',
	components: {
		LoadingLine,
		LoadingSpinner,
	},
	data: () => ({
		menu: null,
		isLoading: false,
	}),
	props: {
		id: {
			type: String,
			default: v4(),
		},
		presentation: {
			type: Object,
			default: () => ({}),
		},
	},
	mounted() {
		this.menu = new MDCMenu(this.$el.querySelector('.mdc-menu'))
	},
	methods: {
		toggleMenu() {
			this.menu.open = !this.menu.open
		},
		copyUrl(presentation) {
			const { presentationId } = presentation
			const { opportunityId } = this.$route.params


			const route = this.$router.resolve({ name: 'presentation', params: { opportunityId, presentationId }})
			const url =[window.location.origin, route.href].join(``)

			try {
				navigator.clipboard.writeText(url)
					.then(() => {
						this.$notice(`Presentation URL copied to clipboard!`)
					})
					.catch((error) => {
						this.$notice(`ERROR: Can not copy URL!`)
					})
			} catch (error) {
				const copyInput = document.querySelector(`#copy_field_${presentationId}`)
				if (!copyInput) return
				copyInput.value = url
				copyInput.select()

				let copied
				try {
					copied = document.execCommand('copy')
				} catch (error) {
					this.$notice(`Can not copy URL`)
				}

				if (copied) {
					this.$notice(`Presentation URL copied to clipboard!`)
				}
			}
		},
		deletePresentation(presentation) {
			this.$confirm(`Do you want to delete this presentation?`, () => {
				this.isLoading = true
				this.$store.dispatch('opportunity/deletePresentation', {
					presentationId: presentation.presentationId,
					opportunityId: this.$route.params.opportunityId,
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.isLoading = false
				})
			})
		},
		updatePresentation(presentation) {
			this.$confirm(`Do you want to update the rates for this presentation?`, () => {
				this.isLoading = true
				this.$store.dispatch('opportunity/refreshPresentation', {
					presentationId: presentation.presentationId,
					opportunityId: this.$route.params.opportunityId,
				})
				.then((res) => {
					this.$notice(`Presentation updated to today's rates.`)
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.isLoading = false
				})
			})
		},
		viewPresentation(presentation) {
			const { presentationId } = presentation
			const { opportunityId } = this.$route.params

			const route = this.$router.resolve({ name: 'presentation', params: { opportunityId, presentationId }})
			window.open(route.href, '_blank')
		},
		sendPresentation(presentation) {
			const { presentationId } = presentation
			const { opportunityId, tab } = this.$route.params

			this.$router.push({name: 'opportunityPresentation', params: { tab, opportunityId, presentationId }})
		}
	},
}
</script>

<style scoped lang="scss">
@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
@use "@material/icon-button";

@include icon-button.core-styles;

.presentation-list {

	&-menu {
		position: relative;
	}

	&__loading {
		bottom: 0;
		position: absolute;
	}
}

.mdc-surface-anchor {
	position: relative;

	.loading-spinner {
		// position: absolute;
		// top: calc(50% - 10px);
		// left: calc(50% - 10px);
	}
}

.mdc-list--icon-list .mdc-list-item__graphic {
	margin-right: 10px;
}

</style>
