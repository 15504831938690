<template>
	<div class="partial partial--opportunity opportunity">
		<div class="partial__heading">
			<router-link :to="{name: 'main' }" class="partial__breadcrumb">
				<ion-icon name="arrow-back" /> Opportunities
			</router-link>
			<h2 class="partial__title">{{ pageTitle }}</h2>

			<div class="partial__actions" v-if="opportunity.opportunityId">
				<a class="button button--text button--primary" @click="deleteOpportunity(opportunity)">
					<span class="button__label">Delete</span>
					<span class="button__icon">
						<ion-icon name="trash" />
					</span>
				</a>

				<router-link tag="button" :to="{name: 'opportunityPresentation'}" class="button button--unelevated button--secondary" :disabled="editing || !canCreatePresentation" :title="editing ? `Please save your changes` : !opportunity.pricingOptions ? `Please add some pricing options` : `Create presentation`">
					<span class="mdc-button__label">Create Presentation</span>
					<span class="mdc-button__icon">
						<ion-icon name="share-alt" />
					</span>
				</router-link>
			</div>
		</div>

		<div class="partial__tabs">
			<tab-bar
				class="partial__tab-bar"
				:items="tabs"
				>
				<template slot-scope="{ item, idx }" slot="item">
					<div
						class="tab-bar__item a"
						:class="{'tab-bar__item--active': item.link == activeTab.link}"
						:id="`opportunity_tab_${idx}`"
						@click="setTab(item)"
						>
						{{ item.label }}
					</div>
				</template>
			</tab-bar>
		</div>

		<!-- PRICING TAB -->
		<template v-if="!tab">
			<card :loading="isLoading || opportunityLoading">
				<div slot="header">
					<h3 class="card__title">
						Opportunity Details
					</h3>
					<div class="opportunity__loan-toggle">
						<span>New Loan</span>
						<switch-field @input="toggleNewLoan" :value="opportunity.existingLoan" class="no-label" :disabled="!editing" />
						<span>Existing Loan</span>
					</div>
					<div class="card__actions">
						<a class="button button--primary button--small" @click="editing = !editing" v-if="!editing">Edit</a>
						<a class="button button--primary button--small" @click="editing = !editing" v-if="editing">Cancel</a>
					</div>
				</div>
				<form-opportunity :opportunity="opportunity" v-if="editing" @editing="toggleEditing" :clients="clients" @orgSelect="getClientList" :userLoading="userLoading" />
				<details-opportunity :opportunity="opportunity" v-if="!editing" />
			</card>

			<card v-for="(pricing, key) in pricingOptions" :key="key" class="opportunity-price"  :class="{'opportunity-price--primary': primaryPrice == key}" :editing="priceEditing[key]">
				<div class="opportunity-price__header" :class="{'opportunity-price__header--active': pricing.active}">
					<div class="opportunity-price__title">
						<h3 class="card__title">Pricing Option {{ (key + 1) }}</h3>

						<checkbox class="form-list-item__field" type="radio" name="primaryPrice" :value="primaryPrice == key" :disabled="!pricing.active || pricingOptions.length < 2" @input="togglePrimaryPrice($event, key)" label="Show Prepayment Scenario" />
					</div>
					<div class="card__actions opportunity-price__actions">
						<a class="button button--primary button--small" @click="deletePriceOption(key)" v-if="!priceEditing[key]">Delete</a>
						<a class="button button--primary button--small" @click="togglePriceEdit(key)" v-if="!priceEditing[key]">Edit</a>
						<a class="button button--primary button--small" @click="togglePriceEdit(key)" v-if="priceEditing[key]">Cancel</a>
						<switch-field @input="togglePriceActive(key)" :id="`toggle_${key}`" :value="pricing.active" :label="`${pricing.active ? 'Active' : 'Inactive'}`" v-if="!priceEditing[key]" :disabled="primaryPrice == key || !pricing.spread || !pricing.maturity || !pricing.amortization" />
					</div>
				</div>
				<form-pricing :pricing="pricing" :opportunity="opportunity" :idx="key" v-if="priceEditing[key] == true" @editing="togglePriceEdit(key); togglePriceActive(key, true);" />
				<details-pricing :pricing="pricing" :opportunity="opportunity" :idx="key" v-if="!priceEditing[key]" />
			</card>

			<div class="partial__actions opportunity__actions">
				<button class="button" @click="addPricingOption()" v-if="opportunity.opportunityId" :disabled="pricingOptions.length > 5">
					<div class="mdc-button__ripple"></div>
					<ion-icon name="add-circle-outline" class="mdc-button__icon" aria-hidden="true"></ion-icon>
					<span class="mdc-button__label">Add Pricing Option</span>
				</button>
			</div>
		</template>
		<!-- /Pricing Tab -->

		<!-- PRESENTATIONS TAB -->
		<template v-if="tab == 'presentations'">
			<list-presentations :loading="isLoading" :presentations="presentations" />
		</template>
		<!-- /Presentations Tab -->

		<!-- ACTIVITIES TAB -->
		<template v-if="tab == 'activity'">
			<list-activity :loading="isLoading" :events="events" />
		</template>
		<!-- /Activities Tab -->

		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="partial__modal" slot="content" name="pageModal" @close="hideModal(true)" />
		</modal>
	</div>
</template>

<script>
import AButton from '@/components/AButton.vue'
import Card from '@/components/Card'
import Checkbox from '@/components/Checkbox'
import DetailsOpportunity from '@/components/DetailsOpportunity'
import DetailsPricing from '@/components/DetailsPricing'
import FormOpportunity from '@/components/FormOpportunity'
import FormPricing from '@/components/FormPricing'
import ListActivity from '@/components/ListActivity'
import ListPresentations from '@/components/ListPresentations'
import Modal from '@/components/Modal'
import SwitchField from '@/components/SwitchField'
import TabBar from '@/components/TabBar'

export default {
	name: 'PartialOpportunity',
	components: {
		AButton,
		Card,
		Checkbox,
		DetailsOpportunity,
		DetailsPricing,
		FormOpportunity,
		FormPricing,
		ListActivity,
		ListPresentations,
		Modal,
		SwitchField,
		TabBar,
	},
	data: () => ({
		activeTab: null,
		isLoading: false,
		editing: false,
		priceEditing: {},
		clients: [],
		userLoading: false,
	}),
	async mounted() {
		this.isLoading = true
		if (this.$route.params.opportunityId) {
			const { opportunityId } = this.$route.params
			try {
				await this.$store.dispatch(`opportunity/get`, opportunityId)
			} catch (error) {
				this.$notice(`ERROR: ${error.message || error}`)
			}
		} else {
			this.editing = true
		}
		this.isLoading = false
	},
	destroyed() {
		this.$store.dispatch('opportunity/unset')
	},
	computed: {
		isAdmin() {
			return !!this.$store.getters['user/isAdmin']
		},
		opportunityLoading() {
			return this.$store.getters['misc/loading']
		},
		modalVisible() {
			return this.$route.name == 'opportunityPresentation'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
		opportunity() {
			const opportunity = this.$store.getters['opportunity/opportunity']
			return opportunity
		},
		presentations() {
			let presentations = this.$store.getters['opportunity/presentations']
			presentations.sort((a, b) => {
				let aDate = (new Date(a.dateModified || a.dateCreated)).getTime()
				let bDate = (new Date(b.dateModified || b.dateCreated)).getTime()
				aDate = isNaN(aDate) ? 0 : aDate
				bDate = isNaN(bDate) ? 0 : bDate

				if (aDate == bDate) {
					console.log('dateCreated', a.dateCreated, b.dateCreated)
					return a.dateCreated < b.dateCreated ? 1 : -1
				}
				return aDate < bDate ? 1 : -1
			})

			return presentations

		},
		canCreatePresentation() {
			const filtered = this.pricingOptions.filter(p => p.spread && p.amortization && p.maturity && p.active)
			return filtered.length > 0
		},
		events() {
			return this.$store.getters['opportunity/events']
		},
		pageTitle() {
			return this.opportunity.opportunityId ? this.opportunity.name || 'Opportunity' : `Create New Opportunity`
		},
		tab() {
			return this.$route.params.tab
		},
		tabs() {
			let tabs = [
				{
					label: 'Pricing',
					link: { tab: undefined },
				},

			]

			if (this.$route.params.opportunityId) {
				tabs = [
					...tabs,
					...[
						{
							label: 'Presentations',
							link: { tab: 'presentations' },
						},
						{
							label: 'Activity',
							link: { tab: 'activity' },
						},
					]
				]
			}

			return tabs
		},
		pricingOptions() {
			let pricingOptions = []

			if (this.opportunity.pricingOptions) {
				pricingOptions = this.opportunity.pricingOptions
			}

			return pricingOptions
		},
		hasActivePricingOption() {
			if (this.pricingOptions) {
				return (this.pricingOptions || []).filter(po => po.active).length > 0
			}
			return false
		},
		primaryPrice() {
			if (this.pricingOptions.length < 2) return 0
			return Math.max(0, this.pricingOptions.findIndex(po => po.isPrimary === true))
		},
	},
	methods: {
		addPricingOption() {
			this.isLoading = true
			this.$store.dispatch('opportunity/addPricing', { opportunity: {...this.opportunity} })
				.then(() => {
					const idx = (this.opportunity.pricingOptions || []).length - 1
					this.priceEditing[idx] = true
					this.$set(this, 'priceEditing', {...this.priceEditing})
				})
				.catch((error) => {
					this.$notice(`Can not add pricing option: ${error.message || error}`)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
		togglePriceEdit(idx) {
			this.priceEditing[idx] = this.priceEditing[idx] == undefined ? true : !this.priceEditing[idx]
			this.$set(this, 'priceEditing', {...this.priceEditing} )
		},
		hideModal(clicked) {
			if (clicked) {
				this.$router.replace({ name: 'opportunity', params: { opportunityId: this.$route.params.opportunityId }})
			} else {
				this.$router.back()
			}
		},
		deleteOpportunity(opportunity) {
			const { opportunityId, name } = opportunity
			this.$confirm(`Are you sure you want to delete Opportunity for "${name}" and all Presentations?`, () => {
				this.isLoading = true
				this.$store.dispatch('opportunity/delete', opportunityId)
					.then((res) => {
						this.$notice(`Opportunity for "${name}" deleted.`)
						this.$router.push({name: 'main'})
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.isLoading = false
					})
			}, {
				title: `Delete Opportunity`,
			})
		},
		deletePriceOption(idx) {
			this.$confirm(`Delete this pricing option?`, () => {
				this.isLoading = true
				this.$store.dispatch('opportunity/deletePricing', { opportunity: {...this.opportunity}, idx })
					.then((res) => {
						delete this.priceEditing[idx]
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.isLoading = false
					})
			})
		},
		setTab(tab) {
			// this.activeTab = tab
			this.$router.push({ name: this.$route.name, params: { opportunityId: this.$route.params.opportunityId, tab: tab.link.tab }})
		},
		toggleEditing(status) {
			this.editing = status
		},
		toggleNewLoan(state) {
			this.$store.dispatch('opportunity/upsert',{existingLoan: state} )
		},
		togglePriceActive(idx, status) {
			if (status == undefined) {
				this.opportunity.pricingOptions[idx].active = !this.opportunity.pricingOptions[idx].active
			} else {
				this.opportunity.pricingOptions[idx].active = status
			}
			this.$store.dispatch('opportunity/update', {opportunityId: this.opportunity.opportunityId, data: {...this.opportunity}} )
		},
		togglePrimaryPrice(evt, idx) {
			this.pricingOptions[this.primaryPrice].isPrimary = false
			this.pricingOptions[idx].isPrimary = true
			this.pricingOptions[idx].active = true

			this.opportunity.pricingOptions = this.pricingOptions

			this.primaryPrice = idx
			this.$store.dispatch('opportunity/update', {opportunityId: this.opportunity.opportunityId, data: {...this.opportunity}} )
			// this.$set(this.opportunity, 'pricingOptions', this.pricingOptions)
		},
		async getClientList(orgId) {
			if (!this.isAdmin) return
			if (orgId) {
				this.userLoading = true
				this.$store.dispatch(`user/list`, { key: 'orgId', value: orgId, op: '==', limit: 10000, })
					.then((res) => {
						this.clients = res
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.userLoading = false
					})
			}
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: function(newVal, oldVal) {
				const activeTab = this.tabs.find(t => t.link.tab == newVal.params.tab)
				if (activeTab) {
					this.activeTab = activeTab

					if (activeTab.link.tab == 'presentations') {
						const { opportunityId } = this.$route.params
						this.isLoading = true
						this.$store.dispatch('opportunity/listPresentations', { opportunityId })
							.catch((error) => this.$notice(`ERROR: ${error.message || error}`))
							.finally(() => {
								this.isLoading = false
							})
					}

					if (activeTab.link.tab == 'activity') {
						const { opportunityId } = this.$route.params
						this.isLoading = true
						this.$store.dispatch('opportunity/listActivity', { opportunityId })
							.catch((error) => this.$notice(`ERROR: ${error.message || error}`))
							.finally(() => {
								this.isLoading = false
							})

					}
				}
			},
		},
		pricingOptions: {
			immediate: true,
			handler: function (newVal) {
				let idx = newVal.find(po => po.isPrimary && po.active)
				if (idx > -1) {
					this.primaryPrice = idx
				}
			},
		},
		'opportunity.orgId': {
			immediate: true,
			handler: function (newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					this.getClientList(newVal)
				}
			},
		},
	},
	metaInfo: () => ({
		title: `Opportunity`,
	}),
}
</script>

<style scoped lang="scss">
.opportunity {
	$opportunity: &;

	&__actions {
		@include modules.gutter('margin-top');
		align-items: center;
		display: flex;
		justify-content: space-between;

		.button {
			margin-left: auto;
		}
	}

	&__loan-toggle {
		align-self: center;
		justify-self: center;
		display: grid;
		flex: 1;
		grid-template-columns: fit-content(10ch) fit-content(8ch) fit-content(10ch);
		column-gap: 1em;
		align-items: center;
		flex-direction: row;
		margin: auto 2rem;
		// justify-content: space-between;
	}

	&-price {
		@include modules.gutter('margin-top');

		#{$opportunity}-price__header {
			@include modules.gutter('padding');
			align-items: center;
			border-bottom: 1px solid modules.color_('border', 'light');
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			transition: background-color 150ms modules.$ease-standard;

			@include modules.media-query('phone') {
				flex-direction: column;
			}

			&--active {
				background-color: modules.color_('secondary');
				border-color: transparent;
			}
		}

		&--primary {
			box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15), 0 0 0px 1px rgba(modules.color_('primary', 'light'), .5);
		}

		#{$opportunity}-price__title {
			display: flex;
			align-items: center;

			@include modules.media-query('phone') {
				flex-direction: column;
				margin-bottom: 12px;
			}

			.card__title {
				border-right: 1px solid modules.color_('border','dark');
				padding-right: 1rem;

				@include modules.media-query('phone') {
					border-right: 0;
					margin-bottom: 1rem;
				}
			}

			.checkbox {
				margin-left: 1rem;
				@include modules.media-query('phone') {
					margin-bottom: 1rem;
					margin-left: 0;
				}
			}
		}

		&__actions {
			.button--primary {
				@include modules.gutter('margin-left', .5);
				@include modules.gutter('margin-right', .5);
			}
		}
	}
}
</style>
