<template>
	<div class="modal modal--presentation modal-presentation">
		<form-presentation @close="$emit('close')" />
	</div>
</template>

<script>
import FormPresentation from '@/components/FormPresentation'

export default {
	name: 'ModalPresentation',
	components: {
		FormPresentation,
	},
}
</script>

<style scoped lang="scss">

</style>
