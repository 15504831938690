<template>
	<form class="form form--presentation form-presentation" @submit.prevent.stop="submitForm(fields)" :novalidate="true">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row">
				<select-field class="form__field" v-model="fields.action" label="Action" :options="actionOptions" v-if="!presentationId" />
				<text-field class="form__field" type="email" hint="comma separated emails" v-model="fields.to" label="Send To" v-if="fields.action == 'send'" :novalidate="true" />
				<text-area class="form__field" v-model="fields.message" label="Message" v-if="fields.action == 'send'" />
			</div>
		</div>
		<div class="form__actions form__actions--vertical">
			<button type="submit" class="form__action button button--unelevated button--secondary" :disabled="isLoading">
				{{ buttonLabel }} Presentation
			</button>
			<a class="button" @click="$emit('close')">
				cancel
			</a>
		</div>
	</form>
</template>

<script>
import { pick } from 'lodash'

import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextArea from '@/components/TextArea'
import TextField from '@/components/TextField'

export default {
	name: 'FormPresentation',
	components: {
		LoadingSpinner,
		SelectField,
		TextArea,
		TextField,
	},
	data: () => ({
		actionOptions: [
			{
				label: 'Create & Save',
				value: 'create',
			},
			{
				label: 'Create & Send',
				value: 'send',
			},
		],
		fields: {
			action: 'send',
		},
		isLoading: false,
	}),
	computed: {
		buttonLabel() {
			if (this.presentationId)
				return `Send`
			return this.actionOptions.find(a => a.value == this.fields.action).label
		},
		opportunity() {
			return this.$store.getters['opportunity/opportunity']
		},
		presentationId() {
			return this.$route.params.presentationId
		},
	},
	methods: {
		async submitForm(fields) {
			let { opportunityId } = this.$route.params
			let { pricingOptions } = this.opportunity
			if (typeof pricingOptions.filter == 'undefined') {
				pricingOptions = Object.values(pricingOptions)
			}

			const presentationData = {
				...pick(this.opportunity, ['name', 'clientId', 'orgId', 'loanAmount', 'loanIndex', 'varConfidence']),
				pricingOptions: pricingOptions.filter(p => p.active),
			}

			this.isLoading = true
			if (this.presentationId) {
				try {
					const presentationId = this.presentationId
					const { to, message } = fields
					await this.$store.dispatch(`opportunity/sendPresentation`, { opportunityId, presentationId, to, message })
					this.$notice(`Presentation sent!`)
					this.$emit('close')
				} catch (error) {
					this.$notice(`ERROR: ${error.message || error}`)
				}

				this.isLoading = false
				return
			}

			let action = `created`
			if (fields.action == 'send') {
				action = `sent`
				presentationData.to = fields.to
				presentationData.message = fields.message
			}

			this.isLoading = true
			try {
				let res = await this.$store.dispatch('opportunity/createPresentation', { opportunityId, data: presentationData })

				const { presentationId } = res
				this.$notice(`Presentation ${action}!`, () => {
					const path = this.$router.resolve({ name: 'presentation', params: { opportunityId, presentationId }})
					window.open(path.href, '_blank')
				}, {
					timeout: 10000,
					actionLabel: 'View Presentation Here'
				})
				this.$emit('close')
			} catch (error) {
				this.$notice(`ERROR: ${error.message || error}`)
			}

			this.isLoading = false
		},
	},
}
</script>

<style scoped lang="scss">
.form {
	width: 44rem;
	max-width: 100%;
}
</style>
