<template>
	<div class="list-presentations">
		<table-list
			:loading="loading"
			:items="presentations"
			:is-sticky="true"
		>
			<template slot="header">
				<span
					:class="`presentations-header-item presentations-header-item--${key}`"
					:key="key"
					v-for="(col, key) in columns"
				>
					{{ col }}
				</span>
				<span class="presentations-header-item presentations-header-item--actions"></span>
			</template>

			<template slot="item" slot-scope="{ item }">
				<div
					:class="`presentations-item presentations-item--${key}`"
					:key="`item_${key}`"
					v-for="(col, key) in columns"
				>
					<span v-html="col" class="presentations-item__label"></span>
					<span v-html="transform(key, item)" class="presentations-item__value"></span>
				</div>
				<div class="presentations-item presentations-item--actions">
					<presentation-list-menu :presentation="item" />
				</div>
			</template>
		</table-list>
	</div>
</template>

<script>
import PresentationListMenu from '@/components/PresentationListMenu'
import TableList from '@/components/TableList'

export default {
	name: 'ListPresentations',
	components: {
		PresentationListMenu,
		TableList,
	},
	props: {
		loading: Boolean,
		presentations: {
			type: Array,
			default: () => ([]),
		},
	},
	data: () => ({
		columns: {
			dateCreated: 'Created',
			dateModified: 'Updated',
			loanAmount: 'Amount',
			existingLoan: 'Type',
			rate: 'Fixed Rate',
			spread: 'Spread',
			maturity: 'MAT',
			amortization: 'AM',
			principalInterest: 'P&I/MO',
			dateStart: 'Start',
		},
	}),
	methods: {
		transform(key, value) {

			const pricingPick = (options, key, sep = `<br />`) => options.map(o => o[key]).join(sep)

			switch(key) {
				case 'existingLoan':
					return value ? `Existing` : `New`
				case 'loanAmount':
					return this.$options.filters.currency((value.loanAmount || 0), false, 0)
				case 'rate':
					return value.pricingOptions.map(o => this.$options.filters.percentage(o.fixedRate) ).join(`<br />`)
					break
				case 'maturity':
				case 'amortization':
					return value.pricingOptions.map(o => `${o[key]} yr`).join(`<br />`)
					// return pricingPick(value.pricingOptions, key)
				case 'principalInterest':
					return value.pricingOptions.map(o => this.$options.filters.currency(o.principalInterest) ).join(`<br />`)
				case 'spread':
				case 'amortizationType':
					return pricingPick(value.pricingOptions, key)
				case 'dateStart':
					return value.pricingOptions.map(o => `${o.dateStart == 0 ? 'Spot' : o.dateStart+'mo'}`).join(`<br />`)
				case 'dateModified':
					// if (!value.dateModified) return '-'
					return `<time datetime="${value.dateModified || value.dateCreated}" title="${this.$options.filters.date(value.dateModified || value.dateCreated, 'Pp')}">${this.$options.filters.date(value.dateModified || value.dateCreated)}</time>`
				case 'dateCreated':
					return `<time datetime="${value.dateCreated}" title="${this.$options.filters.date(value.dateCreated, 'Pp')}">${this.$options.filters.date(value.dateCreated)}</time>`
				case 'actions':
					break
				default:
					return value
					break
			}

			return value
		},
	},
}
</script>

<style scoped lang="scss">


.list-presentations {
	&__heading {
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');
	}

	::v-deep .table-list {
		&__item,
		&__header {
			background-color: modules.color_('background');
			border-bottom: 1px solid modules.color_('background', 'medium');
			display: grid;
			grid-template-columns: repeat(3, minmax(130px, 1fr)) repeat(7, minmax(70px, 1fr)) 60px;

			@include modules.media-query('phone') {
				grid-template-columns: 1fr;
			}
		}

		&__header {
			@include modules.media-query('phone') {
				display: none;
			}
		}

		&__item {
			@include modules.media-query-max-width('desktop-xs') {
				white-space: nowrap;
			}

			@include modules.media-query('phone') {
				@include modules.gutter('padding-top', .75);
				@include modules.gutter('padding-bottom', .75);
			}
		}
	}

	.presentations-header-item {
		@include modules.fontSize(12px);
		font-weight: 600;
		padding: 1.5em;
		text-transform: uppercase;
		letter-spacing: 1px;

		@include modules.media-query('phone') {
			padding: 0.5em 1em;
		}
	}

	.presentations-item {
		color: modules.color_('text', 'dark');
		padding: 1.5em;
		text-decoration: none;
		line-height: 1.75;

		@include modules.media-query('phone') {
			@include modules.fontSize(15px);
			padding: 0.25em 1em;
		}

		&__label {
			@include modules.fontSize(16px);
			font-weight: bold;
			line-height: 24px;
			text-transform: uppercase;
			display: none;

			@include modules.media-query('phone') {
				display: block;
			}
		}

		&:first-of-type {
			@include modules.media-query-max-width('desktop-xs') {
				white-space: initial;
			}
		}

		&--pending {
			&.presentations-item--status {
				color: modules.color_('announce', 'text');
			}
		}

		&--removed {
			opacity: 0.7;

			&.presentations-item--status {
				color: modules.color_('alert', 'critical')
			}
		}

		&--actions {
			padding: 0;
			padding: 0;
    		align-items: center;
    		display: flex;

			@include modules.media-query('phone') {
				justify-content: flex-end;
			}
		}
	}

}
</style>
