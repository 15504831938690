<template>
	<div class="mdc-text-field mdc-text-field--filled mdc-text-field--textarea">
		<span class="mdc-text-field__resizer">
			<textarea class="mdc-text-field__input" aria-labelledby="" :rows="rows" :cols="cols" :maxlength="maxlength" v-bind="attrs" v-on="listeners" @input="input"></textarea>
		</span>
		<span class="mdc-floating-label" :class="{'mdc-floating-label--float-above': attrs.value}" :id="`${id}_label`" v-if="label">{{ label }}</span>
	</div>
</template>

<script>
import { MDCTextField } from '@material/textfield'

export default {
	name: 'TextArea',
	props: {
		autocomplete: String,
		id: String,
		label: String,
		name: String,
		type: String,
		placeholder: String,
		rows: {
			type: Number,
			default: 4,
		},
		cols: {
			type: Number,
			default: 40,
		},
		maxlength: {
			type: Number,
			default: null,
		},
	},
	computed: {
		attrs() {
			return this.$attrs
		},
		listeners() {
			return {
				...this.$listeners,
				input: event => this.input(event)
			}
		},
		hasPre() {
			return Boolean(this.$slots.pre)
		},
		hasPost() {
			return Boolean(this.$slots.post)
		},
	},
	mounted() {
		this.textfield = new MDCTextField(this.$el)
	},
	methods: {
		input(evt) {
			this.$emit('input', evt.target.value)
		},
	},
}
</script>

<style scoped lang="scss">

</style>
