<template>
	<form class="form form--opportunity" @submit.stop.prevent="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-3">
				<text-field label="Borrower Name" v-model="fields.name" class="form__field" autocomplete="nope" />
				<autocomplete-organization  @selected="orgSelect" :value="fields.orgId" :label="organization | organization" v-if="isAdmin" />
				<!-- <autocomplete-user @selected="clientSelect" :value="fields.clientId" :label="fields.client | user" v-if="isAdmin" :orgId="fields.orgId" /> -->
				<select-field label="User" v-model="fields.clientId" :options="userOptions" v-if="isAdmin" :two-line="true" :disabled="userLoading" />

				<text-field type="tel" label="Loan Amount" v-model.lazy="fields.loanAmount" class="form__field" v-money="money" autocomplete="nope" :min="250000" />
				<!-- <select-field label="Index" v-model="fields.loanIndex" :options="loanIndexOptions" :readonly="true" /> -->
				<select-field label="VaR Confidence" v-model="fields.varConfidence" :options="varConfidenceOptions" v-if="isAdmin" />

				<div class="row-2" v-if="isAdmin">
					<text-field type="tel" label="Internal Markup" :value="fields.feeMarkup" class="form__field" :readonly="true" :disabled="true" autocomplete="not-this" v-if="isAdmin">
						<span slot="post">b.p.</span>
					</text-field>
					<text-field type="tel" label="External Markup" :value="fields.feeExternal" class="form__field" :readonly="true" :disabled="true" v-if="isAdmin">
						<span slot="post">b.p.</span>
					</text-field>
				</div>
				<text-field type="number" :label="`Hedge Fee (max ${bpMax} b.p.)`" v-model="fields.feeHedge" class="form__field" step="1" autocomplete="not-this-either" min="0" :max="bpMax">
					<span slot="post">b.p.</span>
				</text-field>
				<text-field type="number" label="Discretionary Fee" v-model="fields.feeDiscretionary" class="form__field" v-if="isAdmin">
					<span slot="post">b.p.</span>
				</text-field>
			</div>

			<div class="form__actions form__actions--horizontal">
				<button class="button button--secondary button--unelevated button--medium form__action" type="submit" :disabled="isLoading">Save</button>
			</div>
		</div>
	</form>
</template>

<script>
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import { get, pick } from 'lodash'
import { LoanType, LoanIndex, AmortizationType } from '@/lib/enums'

import AButton from '@/components/AButton'
import AutocompleteOrganization from '@/components/AutocompleteOrganization'
import AutocompleteUser from '@/components/AutocompleteUser'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'

export default {
	name: 'FormOpportunity',
	components: {
		AutocompleteOrganization,
		AutocompleteUser,
		AButton,
		LoadingSpinner,
		SelectField,
		TextField,
	},
	directives: {
		mask,
		money: VMoney,
	},
	props: {
		loading: Boolean,
		opportunity: {
			type: Object,
			default: () => ({}),
		},
		clients: {
			type: Array,
			default: () => ([]),
		},
		userLoading: Boolean,
	},
	data: () => ({
		isLoading: false,
		defaults:{},
		error: null,
		fields: {
			loanIndex: 'SofrDaily',
			// loanIndex: 'Libor1',
		},
		money: {
			decimal: '.',
			thousands: ',',
			precision: 0,
			prefix: '$',
			masked: false,
		},
		// clients: [],
	}),
	computed: {
		isAdmin() {
			return !!this.$store.getters['user/isAdmin']
		},
		me() {
			return this.$store.getters['user/me']
		},
		loanIndexOptions() {
			let options = []
			for (let key in LoanIndex) {
				options.push({
					value: key,
					label: LoanIndex[key],
				})
			}

			return options
		},
		userOptions() {
			let options = []
			let clients = this.clients.sort((a, b) => a.name > b.name ? 1 : -1)
			for (const client of clients) {
				options.push({
					value: client.uid,
					label: client.name,
					alt: client.email,
				})
			}

			return options
		},
		varConfidenceOptions() {
			let options = [
				{
					value: 0.95,
					label: '95.0%',
				},
				{
					value: 0.90,
					label: '90.0%',
				},
			]

			return options
		},
		organization() {
			if (this.fields.organization && this.fields.organization.name) {
				return this.fields.organization
			} else if (this.fields.client) {
				return this.fields.client.organization
			}
			return {}
		},
		bpMax() {
			if (this.me.organization.settings.maxBp && !this.isAdmin) {
				return Number(this.me.organization.settings.maxBp) * 100
			}

			let externalMarkup = Number(this.fields.feeExternal || 0)
			return Math.max(0, 25 - externalMarkup)
		},
	},
	methods: {
		orgSelect(selected) {
			this.fields.orgId = selected.value
			this.fields.organization = selected.raw
			this.fields.feeMarkup = this.$options.filters.basisPoints(((selected.raw.settings || {}).institutionalMarkup) || 0.10, null)
			this.fields.feeExternal = this.$options.filters.basisPoints(((selected.raw.settings || {}).externalMarkup) || 0, null)

			this.$set(this.fields, 'orgId', selected.value)
			this.$set(this.fields, 'organization', selected.raw)
			this.$emit('orgSelect', selected.value)
		},
		clientSelect(selected) {
			this.fields.clientId = selected.value
			this.fields.client = selected.label
		},
		async submitForm(fields) {
			this.error = null

			const update = {...fields}
			if (update.feeHedge > this.bpMax) {
				return this.$notice(`Please enter a Hedge Fee below ${this.bpMax} b.p.`)
			}
			update.loanAmount = Number(update.loanAmount.replace(/\.|\,|\$/ig, ''))
			update.feeMarkup = update.feeMarkup ? Number(update.feeMarkup) / 100 : 0
			update.feeHedge = update.feeHedge ? Number(update.feeHedge) / 100 : 0
			update.feeDiscretionary = update.feeDiscretionary ? Number(update.feeDiscretionary) / 100 : 0

			if (fields.opportunityId) {
				this.$store.dispatch('opportunity/update', { opportunityId: update.opportunityId, data: update} )
					.then(() => {
						this.$notice(`Opportunity updated!`)
						this.$emit('editing', false)
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
						this.error = error.message || error
					})
			} else {
				this.$store.dispatch('opportunity/create', update).then((res) => {
					this.$notice('Opportunity created!')
					this.$emit('editing', false)
					this.$router.push({name: 'opportunity', params: { opportunityId: res.opportunityId }})
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
					this.error = error.message || error
				})
			}
		},
	},
	watch: {
		opportunity: {
			immediate: true,
			handler: function (prop) {
				this.fields = {
					...this.defaults,
					...this.fields,
					...pick(prop, ['opportunityId', 'client', 'name', 'clientId', 'orgId', 'loanAmount', 'loanIndex', 'varConfidence',  'organization', 'existingLoan']),
					feeMarkup: this.$options.filters.basisPoints(prop.feeMarkup, null),
					feeExternal: this.$options.filters.basisPoints(prop.feeExternal, null),
					feeHedge: this.$options.filters.basisPoints(prop.feeHedge, null),
					feeDiscretionary: this.$options.filters.basisPoints(prop.feeDiscretionary, null),
				}
			},
		},
	},
}
</script>

<style scoped lang="scss">
.row-2 {
	@include modules.gutter('grid-column-gap');
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
</style>
