<template>
	<form class="form form--pricing" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-3">
				<text-field type="tel" label="Existing Rate" v-model="fields.existingRate" :key="`${opportunityId}_existingRate`" class="form__field" :required="true" v-if="opportunity.existingLoan">
					<span slot="post">%</span>
				</text-field>

				<select-field label="Existing Rate Expiration" v-model="fields.dateStart" :key="`${opportunityId}_existingExpiration`" :options="startDates" :required="true" v-if="opportunity.existingLoan" />
				<select-field label="Existing Amortization" v-model="fields.existingAmortization" :key="`${opportunityId}_existingAmortization`" :options="existingAmortizationOptions" :required="true" v-if="opportunity.existingLoan" />
				<select-field :label="`${newPrefix}Amortization`" v-model="fields.amortization" :key="`${opportunityId}_amortization`" :options="amortizationOptions" :required="true" class="form__field" />
				<text-field type="number" :label="`${newPrefix}Spread`" v-model="fields.spread" :key="`${opportunityId}_spread`" v-mask="`###.##`" class="form__field" :required="true" />
				<select-field :label="`${newPrefix}Maturity`" v-model="fields.maturity" :key="`${opportunityId}_maturity`" :options="maturityOptions" :required="true" />
				<select-field label="Start Date" v-model="fields.dateStart" :key="`${opportunityId}_startDate`" :options="startDates" :required="true" v-if="!opportunity.existingLoan" />
			</div>
		</div>

		<div class="form__actions form__actions--horizontal">
			<button type="submit" class="button button--secondary button--unelevated button--medium form__action" :disabled="isLoading">Calculate</button>
		</div>
	</form>
</template>

<script>
import { mask } from 'vue-the-mask'

import AButton from '@/components/AButton'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import { format, addMonths } from 'date-fns'

import { LoanType, LoanIndex, AmortizationType } from '@/lib/enums'
import opportunity from '../lib/store/modules/opportunity'

export default {
	name: 'FormPricing',
	components: {
		AButton,
		LoadingSpinner,
		SelectField,
		TextField,
	},
	directives: {
		mask,
	},
	props: {
		opportunity: Object,
		pricing: Object,
		idx: Number,
	},
	data: () => ({
		fields: {
			amortization: null,
			maturity: null,
		},
		isLoading: false,
		amortizationValues: [3,5,7,10,12,15,20,25,30],
		maturityValues: [3,4,5,6,7,8,9,10,12,15,20],
		existingAmortizationValues: Array.from({length: 30}, (_, i) => i + 1),
	}),
	computed: {
		opportunityId() {
			return opportunity?.opportunityId
		},
		amortizationOptions() {
			let options = []
			for (const v of this.amortizationValues) {
				options.push({
					value: v,
					label: `${v} years`
				})
			}

			return options
		},
		existingAmortizationOptions() {
			let options = []
			for (const v of this.existingAmortizationValues) {
				options.push({
					value: v,
					label: `${v} year${v > 1 ? 's' : ''}`
				})
			}

			return options
		},
		maturityOptions() {
			let options = []
			for (const v of this.maturityValues) {
				if (this.fields.amortization && this.fields.amortization < v) {
					continue
				}
				options.push({
					value: v,
					label: `${v} years`,
				})
			}

			return options
		},
		loanTypeOptions() {
			let options = []
			for (let key in LoanType) {
				options.push({
					value: key,
					label: LoanType[key],
				})
			}

			return options
		},
		amortizationTypeOptions() {
			let options = []
			for (let key in AmortizationType) {
				options.push({
					value: key,
					label: AmortizationType[key],
				})
			}

			return options
		},
		startDates() {
			const now = (new Date())
			const fmt = 'MMM YYY'
			const values = [0, 1, 3, 6, 9, 12, 18, 24]
			const dates = []
			for (const value of values) {
				let t = value == 0 ? `Current` : `${value}mo`
				dates.push({
					value,
					label: `${t} (${format(addMonths(now, value), fmt)})`
				})
			}
			return dates
		},
		newPrefix() {
			return this.opportunity.existingLoan ? `New ` : ``
		},
	},
	methods: {
		submitForm(fields) {
			this.opportunity.pricingOptions[this.idx] = {
				...this.opportunity.pricingOptions[this.idx],
				...fields,
			}

			this.isLoading = true
			this.$store.dispatch('opportunity/update', { opportunityId: this.opportunity.opportunityId, data: this.opportunity} )
				.then(() => {
					this.$notice(`Opportunity updated!`)
					this.$emit('editing', false)
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
					this.error = error.message || error
				})
				.finally(() => {
					this.isLoading = false
				})
		},
	},
	watch: {
		pricing: {
			immediate: true,
			handler: function (prop) {
				this.fields = {
					...prop,
				}
			},
		},
		'fields.amortization'(val) {
			if (this.fields.maturity > val) {
				this.fields.maturity = null
			}
		},
	},

}
</script>

<style scoped lang="scss">
.form--pricing {

	.form__actions {
		@include modules.gutter('padding-bottom');
		@include modules.gutter('padding-right');
	}
}
</style>
